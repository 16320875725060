<template>
  <div>
    <h4 class="section-title">
      {{title}}
      <span class="section-sub-title">資料來源：經濟部商業司</span>
    </h4>
    <div class="table-set">
      <el-table
        :data="currentShareholders.list"
        v-loading="isLoading"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="職稱"
          prop="title">
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="name">
          <template slot-scope="scope">
            {{scope.row.name}}
            <!-- <a href="#" target="_blank" style="margin-left: 4px;"> -->
            <router-link
              target="_blank"
              v-if="scope.row.name&&scope.row.name!='缺額'"
              :to="returnCompanyLinkTo(scope.row.name)">
              <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
            </router-link>
            <!-- </a> -->
          </template>
        </el-table-column>
        <el-table-column
          label="所代表法人"
          prop="ROL">
          <template slot-scope="scope">
            {{scope.row.ROL||'-'}}
            <!-- <a href="#" target="_blank" style="margin-left: 4px;"> -->
            <router-link
              target="_blank"
              v-if="scope.row.ROL_exist"
              :to="returnCompanyLinkTo(scope.row.ROL)">
              <font-awesome-icon :icon="[ 'fas', 'external-link-alt' ]" />
            </router-link>
            <!-- </a> -->
          </template>
        </el-table-column>
        <el-table-column
          v-if="stockType==='股份有限公司'"
          label="持有股份數"
          prop="stocks">
          <template slot-scope="scope">
            {{scope.row.stocks | emptyFormat | numberWithComma}}
          </template>
        </el-table-column>
        <el-table-column
          v-else
          label="出資額(元)"
          align="right">
          <template slot-scope="scope">
            <!-- 有限公司的欄位用contribution -->
            {{scope.row.contribution | emptyFormat | numberWithComma}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="shareholdersSearchableCount"
        @current-change="changeShareholdersPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as config from '@/const/config.js'
import * as apis from '@/apis/index.ts'
import utils from '@/utils/utils.js'
import * as codes from '@/const/codes.ts'

export default {
  props: {
    'title': {
      default () {
        return codes.COMPANY_DETAIL_ITEM_DICT.shareholders
      }
    },
    'shareholders': {
      required: true,
      default () {
        return {
          list: [],
          stat: {}
        }
      }
    },
    'uniID': {
      required: true
    },
    // 公司類別
    'stockType': {
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      currentShareholders: {
        list: [],
        stat: {}
      }
    }
  },
  computed: {
    shareholdersCount () {
      if (this.currentShareholders.stat) {
        return this.currentShareholders.stat['total-count']
      } else {
        return 0
      }
    },
    shareholdersSearchableCount () {
      if (this.currentShareholders.stat) {
        if (this.currentShareholders.stat['total-count'] > config.SEARCH_MAX_COUNT) {
          return config.SEARCH_MAX_COUNT
        } else {
          return this.currentShareholders.stat['total-count']
        }
      } else {
        return 0
      }
    }
  },
  methods: {
    // formatStockNumber (number) {
    //   if (number == null) {
    //     return '-'
    //   } else {
    //     return utils.formatCommaNumber(number)
    //   }
    // },
    // returnRepresentativeLinkTo (representativeName) {
    //   if (!representativeName) {
    //     return null
    //   }
    //   // 預設的比較人物查詢條件
    //   const basicList = [
    //     {
    //       field: 'all',
    //       keyword: representativeName
    //     }
    //   ]
    //   const defaultGet = {
    //     companys: {},
    //     verdicts: {},
    //     news: {
    //       'remove-duplicate': 1
    //     }
    //   }
    //   return {
    //     name: 'multiPersonDetail',
    //     params: {
    //       menuIndex: representativeName
    //     },
    //     query: {
    //       basicList: JSON.stringify(basicList),
    //       get: JSON.stringify(defaultGet)
    //     }
    //   }
    // },
    returnCompanyLinkTo (companyName) {
      if (!companyName) {
        return null
      }
      const dataTypeIndex = codes.DATA_TYPE_INDEX_DIST[codes.DEFAULT_DATA_TYPE]
      const query = {
        basic: {
          get: ['list', 'cls'],
          keyword: companyName,
          keyword_type: 'company',
          'page-index': 1,
          sort: JSON.parse(codes.DEFAULT_COMPANY_LIST_SORT_VALUE)
        }
      }
      const queryString = JSON.stringify(query)
      return {
        name: 'companyList',
        params: {
          dataTypeIndex
        },
        query: {
          originQuery: queryString,
          currentQuery: queryString
        }
      }
    },
    changeShareholdersPage (page) {
      const searchRequest = {
        get: {
          shareholders: {
            'page-index': page
          }
        },
        uniID: this.uniID
      }
      this.isLoading = true
      apis.companyGet(searchRequest)
        .then((data) => {
          this.isLoading = false
          if (data.payload.shareholders) {
            this.currentShareholders = data.payload.shareholders
          }
        })
        .catch(e => {
          this.isLoading = false
        })
    }
  },
  watch: {
    'shareholders': {
      handler (d) {
        if (d) {
          this.currentShareholders = JSON.parse(JSON.stringify(d))
        }
      },
      immediate: true
    }
  }
}
</script>
