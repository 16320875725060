import basicColumnNameMap from '@/const/basicColumnNameMap.js'

export function mapBasicColumnName (data_type, name) {
  let basic = {}
  if (basicColumnNameMap[data_type]) {
    basic = basicColumnNameMap[data_type]
  } else {
    basic = basicColumnNameMap.公司基本資料
  }
  return basic[name]
}