<template>
  <el-dialog
    title="公司資訊"
    class="company-lightbox"
    :visible.sync="syncVisible">
    <div>
      <CompanySummaryAside
        v-loading="isLoading"
        :basic="basic"
        :showLink="true">
      </CompanySummaryAside>
      <ShareholdersTable
        :shareholders="shareholders"
        :uniID="basic.uniID"
        :stockType="getterStockType">
      </ShareholdersTable>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import * as apis from '@/apis/index.ts'
import CompanySummaryAside from '@/components/CompanySummaryAside/CompanySummaryAside.vue'
import ShareholdersTable from '@/components/ShareholdersTable/ShareholdersTable.vue'

export default {
  props: {
    visible: {
      default () {
        return false
      }
    },
    'basic': {
      required: true
    },
    isLoading: {
      default () {
        return false
      }
    }
  },
  components: {
    CompanySummaryAside,
    ShareholdersTable
  },
  data () {
    return {
      isCollection: false,
      // companyTags: [
      //   { text: '營業中', type: 'success' },
      //   { text: '非營業中', type: 'danger' },
      //   { text: '1460', type: '' },
      //   { text: '上市', type: '' }
      // ],
    }
  },
  computed: {
    ...mapGetters([
      'getterUserEmail'
    ]),
    ...mapGetters('companyDetail', [
      'getterStockType',
      'getterIsCompanyPublic' // 為公開發行公司
    ]),
    syncVisible: {
      get () {
        return this.visible
      },
      set (d) {
        this.$emit('update:visible', d)
      }
    },
    shareholders () {
      return {
        list: this.basic.shareholders,
        stat: null
      }
    },
    // 無公司圖象時的覆蓋文字
    avatarCoverText () {
      let text = ''
      if (this.basic.company_name) {
        text = this.basic.company_name.slice(0, 1)
      }
      return text
    },
    companyPhone () {
      if (this.basic.multi && this.basic.multi.phone && this.basic.multi.phone.length) {
        return this.basic.multi.phone[0].value || '-'
      } else {
        return '-'
      }
    },
    companyEmail () {
      if (this.basic.multi && this.basic.multi.email && this.basic.multi.email.length) {
        return this.basic.multi.email[0].value || '-'
      } else {
        return '-'
      }
    },
    companyUrl () {
      if (this.basic.multi && this.basic.multi.url && this.basic.multi.url.length) {
        return this.basic.multi.url[0].value || '-'
      } else {
        return '-'
      }
    },
    companyUrlHref () {
      const companyUrl = this.companyUrl
      if (companyUrl.substr(0, 7) === 'http://' || companyUrl.substr(0, 8) === 'https://') {
        return companyUrl
      }
      return `http://${companyUrl}`
    },
    // companyEmployeeCount () {
    //   if (this.basic.jobs && this.basic.jobs.summary && this.basic.jobs.summary.employee_count >= 0) {
    //     return utils.formatCommaNumber(this.basic.jobs.summary.employee_count)
    //   } else {
    //     return '-'
    //   }
    // },
    companyOperatingTags () {
      let tags = []
      if (this.basic.company_operate === '營業中') {
        tags.push({ text: '營業中', type: 'success' })
      } else {
        tags.push({ text: '非營業中', type: 'danger' })
      }
      // 如果有公開發行資料的話
      if (this.getterIsCompanyPublic) {
        if (this.basic.publics.public_issue) {
          tags.push({ text: this.basic.publics.public_issue, type: 'info'})
        }
        if (this.basic.publics.stock_code) {
          tags.push({ text: this.basic.publics.stock_code, type: 'info'})
        }
      }
      return tags
    },
    etaxMatters () {
      if (this.basic.etax_matters_tideup && this.basic.etax_matters_tideup.length) {
        return this.basic.etax_matters_tideup.map(d => `${d.title}(${d.code})`)
      } else {
        return []
      }
    },
    mapMarkers () {
      // @Q@ 無資料先寫死
      return [{"lat":"22.6421775818","lng":"120.3031005859","text":"測試座標"},{"lat":"25.0407668","lng":"121.5396627","text":"台北101"},{"lat":"25.0482773","lng":"121.5150377","text":"台北火車站"}]
    },
    heartClass () {
      if (this.isCollection == true) {
        return 'color-danger'
      } else {
        return 'color-gray'
      }
    }
  },
  methods: {
    toggleCollection () {
      this.isCollection = !this.isCollection
      if (this.isCollection === true) {
        apis.collectionAdd({
          email: this.getterUserEmail,
          company_uniID: this.basic.uniID,
          company_name: this.basic.company_name,
          note: ''
        })
      } else {
        apis.collectionsDelete({
          email: this.getterUserEmail,
          companys: [this.basic.uniID]
        })
      }
    },
    
  },
  watch: {
    'basic': {
      handler (data) {
        if (data.uniID && this.getterUserEmail) {
          apis.isCollection({
            email: this.getterUserEmail,
            company: data.uniID
          }).then(d => {
            this.isCollection = d.payload || false
          })
        }
      },
      immediate: true
    }
  }
}
</script>
