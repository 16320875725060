export default {
  "公司基本資料": {
      "data_type": "公司類型",
      "main_type": "公司或商業",
      "uniID": "統一編號",
      "uniID_change": "變更統編",
      "company_state": "公司狀況",
      "company_name_en_MOEA": "公司名稱(英文)(國貿局)",
      "company_name": "公司名稱",
      "company_operate": "營業狀況",
      "operate_close_date": "停業時間",
      "company_name_en": "章程所訂外文公司名稱",
      "stock_state": "股權狀況",
      "stock_type": "組織別",
      "capital_amount": "資本總額(元)",
      "paidup_capital_amount": "實收資本額(元)",
      "public_shares": "已發行股份總數(股)",
      "value_per_share": "每股金額(元)",
      "representative_name": "代表人姓名",
      "company_address": "公司所在地",
      "company_county": "公司所在縣市",
      "company_area": "公司所在地區",
      "company_address_etax": "稅籍地址",
      "registration_department": "登記機關",
      "date_approved": "核准設立日期",
      "date_approved_latest": "最後核准變更日期",
      "date_closed_from": "停業日期(起)",
      "date_closed_to": "停業日期(迄)",
      "multi_vote_stocks": "複數表決權特別股",
      "veto_power_stocks": "對於特定事項具否決權特別股",
      "special_power": "特別股股東被選為董事、監察人之禁止或限制或當選一定名額之權利",
      "business_matters": "所營事業資料",
      "etax_matters": "稅籍登記項目",
      "uniID_parent": "總機構統一編號",
      "invoice": "開立發票",
      "staff_count": "員工人數",
      "public_issue": "股票公司狀況",
      "recent_import_performance": "近一年進口實績",
      "recent_export_performance": "近一年出口實績",
      "recent_procurement_amount": "近一個月政府標案所得",
      "shareholders": "董監事",
      "shareholders.sno": "編號",
      "shareholders.title": "職稱",
      "shareholders.name": "姓名",
      "shareholders.ROL": "所代表法人",
      "shareholders.stocks": "所持股票數量",
      "shareholders.contribution": "出資額",
      "managers": "經理人",
      "managers.sno": "編號",
      "managers.name": "姓名",
      "managers.arrival_date": "到職日期",
      "man_related": "其他關係人",
      "man_related.name": "姓名",
      "man_related.title": "職稱",
      "man_related.note": "備註"
  },
  "外國公司登記基本資料": {
      "data_type": "公司類型",
      "main_type": "公司或商業",
      "uniID": "統一編號",
      "uniID_change": "變更統編",
      "company_state": "公司狀況",
      "company_name_en_MOEA": "公司名稱(英文)(國貿局)",
      "company_name": "公司名稱",
      "company_operate": "營業狀況",
      "operate_close_date": "停業時間",
      "company_name_en": "章程所訂外文公司名稱",
      "stock_state": "股權狀況",
      "stock_type": "組織別",
      "capital_amount": "在中華民國境內營運資金",
      "paidup_capital_amount": "實收資本額(元)",
      "public_shares": "已發行股份總數(股)",
      "value_per_share": "每股金額(元)",
      "representative_name": "在中華民國境內負責人",
      "company_address": "分公司所在地",
      "company_county": "分公司所在縣市",
      "company_area": "分公司所在地區",
      "company_address_etax": "稅籍地址",
      "registration_department": "登記機關",
      "date_approved": "核准登記日期",
      "date_approved_latest": "最後核准變更日期",
      "date_closed_from": "停業日期(起)",
      "date_closed_to": "停業日期(迄)",
      "multi_vote_stocks": "複數表決權特別股",
      "veto_power_stocks": "對於特定事項具否決權特別股",
      "special_power": "特別股股東被選為董事、監察人之禁止或限制或當選一定名額之權利",
      "business_matters": "所營事業資料",
      "etax_matters": "稅籍登記項目",
      "uniID_parent": "總機構統一編號",
      "invoice": "開立發票",
      "staff_count": "員工人數",
      "public_issue": "股票公司狀況",
      "recent_import_performance": "近一年進口實績",
      "recent_export_performance": "近一年出口實績",
      "recent_procurement_amount": "近一個月政府標案所得",
      "shareholders": "董監事",
      "shareholders.sno": "編號",
      "shareholders.title": "職稱",
      "shareholders.name": "姓名",
      "shareholders.ROL": "所代表法人",
      "shareholders.stocks": "所持股票數量",
      "shareholders.contribution": "出資額",
      "managers": "經理人",
      "managers.sno": "編號",
      "managers.name": "姓名",
      "managers.arrival_date": "到職日期",
      "man_related": "其他關係人",
      "man_related.name": "姓名",
      "man_related.title": "職稱",
      "man_related.note": "備註"
  },
  "外國公司認許基本資料": {
      "data_type": "公司類型",
      "main_type": "公司或商業",
      "uniID": "統一編號",
      "uniID_change": "變更統編",
      "company_state": "公司狀況",
      "company_name_en_MOEA": "公司名稱(英文)(國貿局)",
      "company_name": "公司名稱",
      "company_operate": "營業狀況",
      "operate_close_date": "停業時間",
      "company_name_en": "章程所訂外文公司名稱",
      "stock_state": "股權狀況",
      "stock_type": "組織別",
      "capital_amount": "在中華民國境內營運資金",
      "paidup_capital_amount": "實收資本額(元)",
      "public_shares": "已發行股份總數(股)",
      "value_per_share": "每股金額(元)",
      "representative_name": "在中華民國境內負責人",
      "company_address": "分公司所在地",
      "company_county": "分公司所在縣市",
      "company_area": "分公司所在地區",
      "company_address_etax": "稅籍地址",
      "registration_department": "登記機關",
      "date_approved": "核准登記日期",
      "date_approved_latest": "最後核准變更日期",
      "date_closed_from": "停業日期(起)",
      "date_closed_to": "停業日期(迄)",
      "multi_vote_stocks": "複數表決權特別股",
      "veto_power_stocks": "對於特定事項具否決權特別股",
      "special_power": "特別股股東被選為董事、監察人之禁止或限制或當選一定名額之權利",
      "business_matters": "所營事業資料",
      "etax_matters": "稅籍登記項目",
      "uniID_parent": "總機構統一編號",
      "invoice": "開立發票",
      "staff_count": "員工人數",
      "public_issue": "股票公司狀況",
      "recent_import_performance": "近一年進口實績",
      "recent_export_performance": "近一年出口實績",
      "recent_procurement_amount": "近一個月政府標案所得",
      "shareholders": "董監事",
      "shareholders.sno": "編號",
      "shareholders.title": "職稱",
      "shareholders.name": "姓名",
      "shareholders.ROL": "所代表法人",
      "shareholders.stocks": "所持股票數量",
      "shareholders.contribution": "出資額",
      "managers": "經理人",
      "managers.sno": "編號",
      "managers.name": "姓名",
      "managers.arrival_date": "到職日期",
      "man_related": "其他關係人",
      "man_related.name": "姓名",
      "man_related.title": "職稱",
      "man_related.note": "備註"
  },
  "外國公司報備基本資料": {
      "data_type": "公司類型",
      "main_type": "公司或商業",
      "uniID": "統一編號",
      "uniID_change": "變更統編",
      "company_state": "公司狀況",
      "company_name_en_MOEA": "公司名稱(英文)(國貿局)",
      "company_name": "公司名稱",
      "company_operate": "營業狀況",
      "operate_close_date": "停業時間",
      "company_name_en": "章程所訂外文公司名稱",
      "stock_state": "股權狀況",
      "stock_type": "組織別",
      "capital_amount": "資本總額(元)",
      "paidup_capital_amount": "實收資本額(元)",
      "public_shares": "已發行股份總數(股)",
      "value_per_share": "每股金額(元)",
      "representative_name": "在中華民國境內代表人",
      "company_address": "辦事處所在地",
      "company_county": "辦事處所在縣市",
      "company_area": "辦事處所在地區",
      "company_address_etax": "稅籍地址",
      "registration_department": "登記機關",
      "date_approved": "核准登記日期",
      "date_approved_latest": "最後核准變更日期",
      "date_closed_from": "停業日期(起)",
      "date_closed_to": "停業日期(迄)",
      "multi_vote_stocks": "複數表決權特別股",
      "veto_power_stocks": "對於特定事項具否決權特別股",
      "special_power": "特別股股東被選為董事、監察人之禁止或限制或當選一定名額之權利",
      "business_matters": "代表人在中華民國境內所為的法律行為",
      "etax_matters": "稅籍登記項目",
      "uniID_parent": "總機構統一編號",
      "invoice": "開立發票",
      "staff_count": "員工人數",
      "public_issue": "股票公司狀況",
      "recent_import_performance": "近一年進口實績",
      "recent_export_performance": "近一年出口實績",
      "recent_procurement_amount": "近一個月政府標案所得",
      "shareholders": "董監事",
      "shareholders.sno": "編號",
      "shareholders.title": "職稱",
      "shareholders.name": "姓名",
      "shareholders.ROL": "所代表法人",
      "shareholders.stocks": "所持股票數量",
      "shareholders.contribution": "出資額",
      "managers": "經理人",
      "managers.sno": "編號",
      "managers.name": "姓名",
      "managers.arrival_date": "到職日期",
      "man_related": "其他關係人",
      "man_related.name": "姓名",
      "man_related.title": "職稱",
      "man_related.note": "備註"
  },
  "外國公司辦事處登記基本資料": {
      "data_type": "公司類型",
      "main_type": "公司或商業",
      "uniID": "統一編號",
      "uniID_change": "變更統編",
      "company_state": "公司狀況",
      "company_name_en_MOEA": "公司名稱(英文)(國貿局)",
      "company_name": "公司名稱",
      "company_operate": "營業狀況",
      "operate_close_date": "停業時間",
      "company_name_en": "章程所訂外文公司名稱",
      "stock_state": "股權狀況",
      "stock_type": "組織別",
      "capital_amount": "資本總額(元)",
      "paidup_capital_amount": "實收資本額(元)",
      "representative_name": "代表人在中華民國境內所為的法律行為",
      "company_address": "辦事處所在地",
      "company_county": "辦事處所在縣市",
      "company_area": "辦事處所在地區",
      "company_address_etax": "稅籍地址",
      "registration_department": "登記機關",
      "date_approved": "核准登記日期",
      "date_approved_latest": "最後核准變更日期",
      "date_closed_from": "停業日期(起)",
      "date_closed_to": "停業日期(迄)",
      "multi_vote_stocks": "複數表決權特別股",
      "veto_power_stocks": "對於特定事項具否決權特別股",
      "special_power": "特別股股東被選為董事、監察人之禁止或限制或當選一定名額之權利",
      "business_matters": "所營事業資料",
      "etax_matters": "稅籍登記項目",
      "uniID_parent": "總機構統一編號",
      "invoice": "開立發票",
      "staff_count": "員工人數",
      "public_issue": "股票公司狀況",
      "recent_import_performance": "近一年進口實績",
      "recent_export_performance": "近一年出口實績",
      "recent_procurement_amount": "近一個月政府標案所得",
      "shareholders": "董監事",
      "shareholders.sno": "編號",
      "shareholders.title": "職稱",
      "shareholders.name": "姓名",
      "shareholders.ROL": "所代表法人",
      "shareholders.stocks": "所持股票數量",
      "shareholders.contribution": "出資額",
      "managers": "經理人",
      "managers.sno": "編號",
      "managers.name": "姓名",
      "managers.arrival_date": "到職日期",
      "man_related": "其他關係人",
      "man_related.name": "姓名",
      "man_related.title": "職稱",
      "man_related.note": "備註"
  },
  "大陸公司許可報備基本資料": {
      "data_type": "公司類型",
      "main_type": "公司或商業",
      "uniID": "統一編號",
      "uniID_change": "變更統編",
      "company_state": "公司狀況",
      "company_name_en_MOEA": "公司名稱(英文)(國貿局)",
      "company_name": "公司名稱",
      "company_name_alias": "公司名稱(大陸地區)",
      "company_operate": "營業狀況",
      "operate_close_date": "停業時間",
      "company_name_en": "章程所訂外文公司名稱",
      "stock_state": "股權狀況",
      "stock_type": "組織別",
      "capital_amount": "資本總額(元)",
      "paidup_capital_amount": "實收資本額(元)",
      "representative_name": "訴訟及非訴訟代理人姓名",
      "company_address": "辦事處所在地",
      "company_county": "辦事處所在縣市",
      "company_area": "辦事處所在地區",
      "company_address_etax": "稅籍地址",
      "registration_department": "登記機關",
      "date_approved": "核准許可報備日期",
      "date_approved_latest": "最後核准變更日期",
      "date_closed_from": "停業日期(起)",
      "date_closed_to": "停業日期(迄)",
      "multi_vote_stocks": "複數表決權特別股",
      "veto_power_stocks": "對於特定事項具否決權特別股",
      "special_power": "特別股股東被選為董事、監察人之禁止或限制或當選一定名額之權利",
      "business_matters": "代表人在台灣地區業務活動範圍",
      "etax_matters": "稅籍登記項目",
      "uniID_parent": "總機構統一編號",
      "invoice": "開立發票",
      "staff_count": "員工人數",
      "public_issue": "股票公司狀況",
      "recent_import_performance": "近一年進口實績",
      "recent_export_performance": "近一年出口實績",
      "recent_procurement_amount": "近一個月政府標案所得",
      "shareholders": "董監事",
      "shareholders.sno": "編號",
      "shareholders.title": "職稱",
      "shareholders.name": "姓名",
      "shareholders.ROL": "所代表法人",
      "shareholders.stocks": "所持股票數量",
      "shareholders.contribution": "出資額",
      "managers": "經理人",
      "managers.sno": "編號",
      "managers.name": "姓名",
      "managers.arrival_date": "到職日期",
      "man_related": "其他關係人",
      "man_related.name": "姓名",
      "man_related.title": "職稱",
      "man_related.note": "備註"
  },
  "大陸公司許可基本資料": {
      "data_type": "公司類型",
      "main_type": "公司或商業",
      "uniID": "統一編號",
      "uniID_change": "變更統編",
      "company_state": "公司狀況",
      "company_name_en_MOEA": "公司名稱(英文)(國貿局)",
      "company_name": "公司名稱",
      "company_name_alias": "公司名稱(大陸地區)",
      "company_operate": "營業狀況",
      "operate_close_date": "停業時間",
      "company_name_en": "章程所訂外文公司名稱",
      "stock_state": "股權狀況",
      "stock_type": "組織別",
      "capital_amount": "在台灣地區營業所用",
      "paidup_capital_amount": "實收資本額(元)",
      "representative_name": "訴訟及非訴訟代理人姓名",
      "company_address": "分公司所在地",
      "company_county": "分公司所在縣市",
      "company_area": "分公司所在地區",
      "company_address_etax": "稅籍地址",
      "registration_department": "登記機關",
      "date_approved": "核准設立日期",
      "date_approved_latest": "最後核准變更日期",
      "date_closed_from": "停業日期(起)",
      "date_closed_to": "停業日期(迄)",
      "multi_vote_stocks": "複數表決權特別股",
      "veto_power_stocks": "對於特定事項具否決權特別股",
      "special_power": "特別股股東被選為董事、監察人之禁止或限制或當選一定名額之權利",
      "business_matters": "所營事業資料",
      "etax_matters": "稅籍登記項目",
      "uniID_parent": "總機構統一編號",
      "invoice": "開立發票",
      "staff_count": "員工人數",
      "public_issue": "股票公司狀況",
      "recent_import_performance": "近一年進口實績",
      "recent_export_performance": "近一年出口實績",
      "recent_procurement_amount": "近一個月政府標案所得",
      "shareholders": "董監事",
      "shareholders.sno": "編號",
      "shareholders.title": "職稱",
      "shareholders.name": "姓名",
      "shareholders.ROL": "所代表法人",
      "shareholders.stocks": "所持股票數量",
      "shareholders.contribution": "出資額",
      "managers": "經理人",
      "managers.sno": "編號",
      "managers.name": "姓名",
      "managers.arrival_date": "到職日期",
      "man_related": "其他關係人",
      "man_related.name": "姓名",
      "man_related.title": "職稱",
      "man_related.note": "備註"
  },
  "商業登記基本資料": {
      "data_type": "公司類型",
      "main_type": "公司或商業",
      "uniID": "商業統一編號",
      "uniID_change": "變更統編",
      "company_state": "公司狀況",
      "company_name_en_MOEA": "商業名稱(英文)(國貿局)",
      "company_name": "商業名稱",
      "company_operate": "營業狀況",
      "operate_close_date": "停業時間",
      "company_name_en": "章程所訂外文公司名稱",
      "stock_state": "股權狀況",
      "stock_type": "組織別",
      "capital_amount": "資本總額(元)",
      "paidup_capital_amount": "實收資本額(元)",
      "representative_name": "負責人姓名",
      "company_address": "公司所在地",
      "company_county": "公司所在縣市",
      "company_area": "公司所在地區",
      "company_address_etax": "稅籍地址",
      "registration_department": "登記機關",
      "date_approved": "核准設立日期",
      "date_approved_latest": "最後核准變更日期",
      "date_closed_from": "停業日期(起)",
      "date_closed_to": "停業日期(迄)",
      "multi_vote_stocks": "複數表決權特別股",
      "veto_power_stocks": "對於特定事項具否決權特別股",
      "special_power": "特別股股東被選為董事、監察人之禁止或限制或當選一定名額之權利",
      "business_matters": "所營事業資料",
      "etax_matters": "稅籍登記項目",
      "uniID_parent": "總機構統一編號",
      "invoice": "開立發票",
      "staff_count": "員工人數",
      "public_issue": "股票公司狀況",
      "recent_import_performance": "近一年進口實績",
      "recent_export_performance": "近一年出口實績",
      "recent_procurement_amount": "近一個月政府標案所得",
      "shareholders": "合夥人姓名",
      "shareholders.sno": "編號",
      "shareholders.title": "職稱",
      "shareholders.name": "姓名",
      "shareholders.stocks": "出資額(元)"
  }
}
